<template>
  <v-container
    id="returns-list"
    tag="section"
    
    fluid
  >
    <ReturnFilters @onFiltersChanged="onFiltersChanged" />
    <v-sheet
      elevation="3"
      rounded
    >
      <v-data-table
        :footer-props="table.footerProps"
        :headers="table.headers"
        :items="result.items"
        :loading="result.loading"
        :options.sync="table.options"
        :page="table.options.page"
        :server-items-length="result.count"
        @update:page="load"
        @update:sort-by="load"
        @update:sort-desc="load"
      >
        <template v-slot:loading>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.loading') }}
          </v-sheet>
        </template>
      
        <template v-slot:no-data>
          <v-sheet
            class="text-center pa-6"
          >
            {{ $t('global.no-data') }}
          </v-sheet>
        </template>

        <template v-slot:item.edition="{ item }">
          <v-btn
            icon
            :href="'/returns/' + item.id"
          >
            <v-icon
              color="blue lighten-1"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.product="{ item }">
          <v-card
            outlined
            color="transparent"
          >
            <v-card-title class="pt-1 pb-1">
              {{ item.order_item.product.name }}
            </v-card-title>
            <v-list-item two-line>
              <v-list-item-avatar
                tile
                size="60"
                class="mt-0 mb-0"
                color="grey"
              >
                <v-img :src="item.order_item.product.photo_url" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle class="pt-2 pb-2">
                  {{ item.order_item.product.sku }} - {{ item.order_item.product.sizes }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ countryCodeEmoji(item.order_item.store.country_code) }}
                  {{ item.order_item.store.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
        <template v-slot:item.free="{ item }">
          {{ item.return_request.free ? $t('views.return_request.free.true') : $t('views.return_request.free.false') }}
        </template>
        <template v-slot:item.orderReference="{ item }">
          <a
            :href="`/tracking/list?order_item%3Aorder_reference=${item.order_item.order_reference}`"
            class="pr-1"
            style="display: inline-block"
          >
            {{ item.order_item.order_reference }}
          </a>
          <v-tooltip
            v-if="item.order_item_incoherent"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  small
                  color="orange"
                >
                  mdi-alert
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('views.return_request.message.order_item_incoherent') }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.requestedAt="{ item }">
          {{ item.return_request.requested_at|date }}
        </template>
        <template v-slot:item.customer="{ item }">
          <template v-if="item.shipment?.sender_address">
            {{ countryCodeEmoji(item.shipment.sender_address.country_code) }}
            {{ item.shipment.sender_address.recipient_name }}
          </template>
        </template>
        <template v-slot:item.shipment="{ item }">
          <template v-if="item.shipment">
            <a :href="`/shipments/${item.shipment.id}`">
              {{ $t('views.return_request.labels.see_shipment') }}
            </a>
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template v-slot:item.validation="{ item }">
          <template v-if="item.validation">
            <template v-if="item.validation === 'accepted'">
              ✓
            </template>
            <template v-else-if="item.validation === 'refused'">
              ✕
            </template>
            <template v-else>
              -
            </template>
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template v-slot:item.status="{ item }">
          <ItemReturnStatusChip :status="item.status" />
        </template>
      </v-data-table>
    </v-sheet>
    <Snackbar />
  </v-container>
</template>

<script>
import { countryCodeEmoji } from '@/helpers/countries'
import { getQueryString } from '@/helpers/queryString'
import ItemReturnStatusChip from '@/components/base/Return/ItemReturnStatusChip'
import ReturnFilters from '@/views/Returns/Filters.vue'
import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'ReturnsList',
  components: { ItemReturnStatusChip, Snackbar, ReturnFilters },
  mixins: [snackbarMixin],
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      filters: {
        store_id: this.$route.query['store_id'],
        order_reference: this.$route.query['order_reference'],
        'requested_at[after]': this.$route.query['requested_at[after]'],
        'requested_at[before]': this.$route.query['requested_at[before]'],
        to_validate_only: this.$route.query['to_validate_only'],
        label_to_generate_only: this.$route.query['label_to_generate_only'],
        to_close_only: this.$route.query['to_close_only'],
        is_free: this.$route.query['is_free'],
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
      table: {
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
        headers: [
          {
            value: 'edition',
            text: '',
            width: 30,
            sortable: false,
          },
          {
            value: 'product',
            text: this.$t('views.return_request.headers.product'),
            sortable: false,
          },
          {
            value: 'status',
            text: this.$t('views.return_request.headers.status'),
            sortable: false,
          },
          {
            value: 'free',
            text: this.$t('views.return_request.headers.type'),
            sortable: false,
          },
          {
            value: 'orderReference',
            text: this.$t('views.return_request.headers.reference'),
            sortable: false,
          },
          {
            value: 'requestedAt',
            text: this.$t('views.return_request.headers.request_date'),
            sortable: false,
          },
          {
            value: 'shipment',
            text: this.$t('views.return_request.headers.shipment'),
            sortable: false,
          },
          {
            value: 'customer',
            text: this.$t('views.return_request.headers.customer'),
            sortable: false,
          },
          {
            value: 'validation',
            text: this.$t('views.return_request.headers.validation'),
            sortable: false,
          },
        ],
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortBy: [],
          sortDesc: [],
          multiSort: false,
        },
      },
    }
  },
  computed: {
    queryString: function () {
      return 'item_returns?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc
      )
    },
  },
  watch: {
    queryString: {
      handler() {
        this.load()
      },
    },
    'table.options.page': function(value) {
      this.$router.replace({ name: 'ReturnsList', query:
          { ...this.filters, page : value } }
      )
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged(filters) {
      this.filters = filters
      this.$router.replace({ name: 'ReturnsList', query:
          { ...this.filters, page : this.table.options.page } })
    },
    cancel() {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.result.loading = true
      this.result.items = []
      this.result.count = 0
      
      this.$axios.get(this.queryString, { cancelToken: axiosSource.token })
        .then((response) => {
          this.result.items = response.data['hydra:member']
          this.result.count = response.data['hydra:totalItems']
          this.result.loading = false
        })
        .catch((error) => {
          if (!this.$axios.isCancel(error)) {
            // eslint-disable-next-line no-console
            console.log(error)
            this.result.loading = false
          }
        })
        .finally(() => {
          this.request = null
        })
    },
    countryCodeEmoji,
  },
}
</script>

<style scoped>

</style>
