<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="action"
            clearable
            hide-details
            label="Actions à effectuer"
            single-line
            :items="actions"
            item-text="label"
            item-value="value"
            outlined
            dense
            prepend-inner-icon="mdi-checkbox-marked-circle-auto-outline"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters.is_free"
            clearable
            hide-details
            label="Type de retour"
            single-line
            :items="returnTypes"
            item-text="label"
            item-value="value"
            outlined
            dense
            prepend-inner-icon="mdi-clipboard-arrow-left"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-menu
            v-model="datePickers.from.show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datePickers.from.value"
                label="Du"
                dense
                outlined
                hide-details
                prepend-inner-icon="mdi-calendar"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="datePickers.from.value"
              @input="datePickers.from.show = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-menu
            v-model="datePickers.to.show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datePickers.to.value"
                label="Au"
                dense
                outlined
                hide-details
                prepend-inner-icon="mdi-calendar"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="datePickers.to.value"
              @input="datePickers.to.show = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <StoreSelector
            v-model="filters.store_id"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters.order_reference"
            label="Ref. Commande"
            clearable
            hide-details
            outlined
            dense
            prepend-inner-icon="mdi-cart-variant"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import StoreSelector from '@/components/base/StoreSelector.vue'

export default {
  name: 'ReturnFilters',
  components: { StoreSelector },
  data() {
    return {
      filters: {
        store_id: this.$route.query['store_id'],
        order_reference: this.$route.query['order_reference'],
        'requested_at[after]': this.$route.query['requested_at[after]'],
        'requested_at[before]': this.$route.query['requested_at[before]'],
        to_validate_only: this.$route.query['to_validate_only'],
        label_to_generate_only: this.$route.query['label_to_generate_only'],
        to_close_only: this.$route.query['to_close_only'],
        is_free: this.$route.query['is_free'],
      },
      datePickers: {
        from: { show: false, value: this.$route.query['requested_at[after]'] },
        to: { show: false, value: this.$route.query['requested_at[before]'] },
      },
      action: (
        this.filters?.to_validate_only === '1' ? 'to_validate_only' :
          this.filters?.label_to_generate_only === '1' ? 'label_to_generate_only' :
            this.filters?.to_close_only === '1' ? 'to_close_only' :
              null
      ),
      returnTypes: [
        {
          value: '1',
          label: this.$t('views.return_request.free.true'),
        },
        {
          value: '0',
          label: this.$t('views.return_request.free.false'),
        },
      ],
      actions: [
        {
          value: 'to_validate_only',
          label: 'À valider',
        },
        {
          value: 'label_to_generate_only',
          label: 'Bordereau à générer',
        },
        {
          value: 'to_close_only',
          label: 'À clore',
        },
      ],
    }
  },
  watch: {
    'datePickers.from.value'(newValue) {
      let value = null
      if (newValue !== null) {
        value = newValue + ' 00:00:00'
      }
      this.filters['requested_at[after]'] = value
    },
    'datePickers.to.value'(newValue) {
      let value = null
      if (newValue !== null) {
        value = newValue + ' 23:59:59'
      }
      this.filters['requested_at[before]'] = value
    },
    action(newValue) {
      const filters = Object.assign({}, this.filters)
      this.actions.forEach(action => delete filters[action.value])
      if (newValue !== null) {
        filters[newValue] = '1'
      }

      this.filters = filters
    },
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>
